import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import ContextProvider from '~/provider/ContextProvider'

import { GlobalStyle } from '~/utils/styles'
// import Footer from '../components/Footer'
import useWindowSize from '../utils/windowSize'

const Wrapper = styled.div`
  font-family: 'neue-haas-grotesk-display';
`

const Layout = ({ children }) => {
  const size = useWindowSize()
  const app = useRef()
  const scrollContainer = useRef()
  const [sizeUsed, setSizeUsed] = useState(0)

  const data = {
    ease: 0.5,
    current: 0,
    previous: 0,
    rounded: 0,
  }

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  })

  if (size && sizeUsed !== size.height) {
    setSizeUsed(size.height)
  }

  useEffect(() => {
    setBodyHeight()
  }, [sizeUsed])

  const setBodyHeight = () => {
    if (typeof window !== 'undefined') {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
  }

  // Scrolling
  const skewScrolling = () => {
    if (typeof window !== undefined) {
      data.current = window.scrollY
      data.previous += (data.current - data.previous) * data.eas
      data.rounded = Math.round(data.previous * 100) / 100

      const difference = data.current - data.rounded
      const acceleration = difference / size.width
      const velocity = +acceleration
      const skew = velocity * 0

      if (scrollContainer.current) {
        scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0) skewY(${skew}deg)`
      }

      requestAnimationFrame(() => skewScrolling())
    }
  }

  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <section ref={app} className="App">
            <Wrapper ref={scrollContainer} className="scroll">
              {children}
            </Wrapper>
            {/* <Footer /> */}
          </section>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
